import React, {useEffect, useState} from 'react';
import EinloggenView from './einloggenView';
import {useNavigate} from "react-router-dom";
import { login } from '../loginUserAWS';


const Einloggen: React.FC = () => {

    const navigate = useNavigate();


    const [nutzer, setNutzer] = useState<any>({

        username: process.env.REACT_APP_COGNITO_USER,
        password: ''
    })

    const einmelden = async (e: React.ChangeEvent<HTMLInputElement>) => {

        const {name, value} = await e.target;
        setNutzer((antes: any) => ({
            ...antes, 
            [name]: value
        }))

    
    }


    const verify = async (e: React.FormEvent<HTMLFormElement>) => {
       
        e.preventDefault();
        try {
  
        const normal = "normal";
        
        await login(nutzer.username, nutzer.password)

              await navigate("/start", { state: { normal }  });
        }
        catch(err) {
            console.log({message: "error by authentication", err})
        }

    }



    return <EinloggenView einmelden={einmelden} verify={verify}/>
}




export default Einloggen
