import React, {useEffect, useState} from 'react';
import AdminView from './adminView';
import {useNavigate} from "react-router-dom";
import  { login } from '../loginUserAWS';
import { jwtDecode } from "jwt-decode";


const Admin: React.FC = () => {

    const navigate = useNavigate();


    const [nutzer, setNutzer] = useState<any>({

        username: '',
        password: ''
    })

    const einmelden = async (e: React.ChangeEvent<HTMLInputElement>) => {

        const {name, value} = await e.target;
        setNutzer((antes: any) => ({
            ...antes, 
            [name]: value
        }))

    
    }


    const verify = async (e: React.FormEvent<HTMLFormElement>) => {
       
        e.preventDefault();

        try {
        
     await login(nutzer.username, nutzer.password);

     const token = await sessionStorage.getItem('idToken'); ;

    if (token) {
    const decoded: any = await jwtDecode(token === null ? "" : token);
    if (decoded) {
        const  groups: string = decoded['cognito:groups']; // Beispiel für einen Claim
        const status = groups[0];

        await navigate("/start", { state: { status } }); 
      
      } else {
        console.log("Token konnte nicht dekodiert werden.");
      }
   
    }


           
            
        }

            catch(err) {
                console.log({message: "error by authentication", err})
            }
        
    }





    return <AdminView einmelden={einmelden} verify={verify}/>
}




export default Admin;
