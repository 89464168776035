import React, { useEffect, useState, MouseEvent } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import HomeView from "./HomeVIew";
import Erschaffen from "../erschaffen/erschaffen";
import HomeMobileView from "./HomeMobileVIew";
import "./Home.css";
import { Hola } from "../interfaces";
import { useLocalStorage, useRafState } from "react-use";
import MediaQuery from "react-responsive";
import Spinner from "../spinner/reload";
import KonditionalModal from "../modal/KonditionalModal";
import {checkAndRefreshToken} from "../loginUserAWS";
const AWS = require("aws-sdk");



const Home: React.FC<Hola> = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const param1 = searchParams.get("param1");
  const param2 = searchParams.get("param2");
  const level2 = location.state?.level;
  const filterEtappe = location.state?.filterEtappe;
  const filterLiturgisch = location.state?.filterLiturgisch;
  const filterThematisch = location.state?.filterThematisch;
  


  const [data, setData] = useState<Hola[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [specificLied, setSpecificLied] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [MullModal, setMullModal] = useState({
    mullModalKonditional: false,
    id: ""
  });
  const [reload, setReload] = useState(false);
  const [gespeicherteFavoriten, setGespeicherteFavoriten] = useLocalStorage<
    number[]
  >("favoriten");
  const [noData, setNoData] = useState(false);
  const [favoriten, setFavoriten] = useState<number[]>(
    gespeicherteFavoriten === undefined ? [] : gespeicherteFavoriten
  );
  const [entfernungskonditional, setEntfernungskonditional] = useState(false);
  const verzeichnise:string[] = [
    "Etappen",
    "Thematisch",
    "Liturgisch",
  ];
  const liturgisch: string[] = [
    "Advent-Weinachten",
    "Fastenzeit",
    "Ostern-Pfingsten",
    "Jahreskreis",
  ];

  const thematisch: string[] = [
    "Marienlieder",
    "Lieder-für-die-Kinder",
    "Einzugslieder",
    "Frieden-Gabenbereitung",
    "Brotbrechen",
    "Kelchkommunion",
    "Auszugslieder",
  ];

  const [gespeicherteLevel, setGespeicherteLevel] = useLocalStorage(
    "level",
    ""
  );

  const [gespeicherteFilterLied, setGespeicherteFilterLied] = useLocalStorage(
    "zweiteKategorie",
    ""
  );

  const [ersteKategorie, setErsteKategorie] = useState<string>(
    param2 === "" ? "Alle"  : param2 === null ? "Alle" : param2
  );
  const [zweiteKategorie, setZweiteKategorie] = useState(
    param2 === null ? gespeicherteFilterLied : param2
  );


  const [gespeicherteSucht, setGespeicherteSucht] = useLocalStorage(
    "sucht",
    ""
  );

  const [sucht, setSucht] = useState(
    param1 === null ? `${gespeicherteSucht}` : `${param1}`
  );

  const level = level2 === undefined ? `${gespeicherteLevel}` : level2;

  const fetchWithRetry = async (url: RequestInfo | URL, options: RequestInit | undefined, retries = 3, delay = 2000) => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, options);
        if (response.ok) {
          return response;
        }
        if (response.status >= 500) {
          throw new Error(`Server Error: ${response.status}`);
        }
      } catch (error) {
        console.error(`Retry ${i + 1} failed:`, error);
        if (i < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, delay)); // Wartezeit vor erneutem Versuch
        } else {
          throw error; // Letzter Versuch gescheitert
        }
      }
    }
  };

  const token = sessionStorage.getItem("accessToken");


  const fetchData = async (page:number) => {
    const limit = 100;
    const offset = page * limit
    const url = `${process.env.REACT_APP_API_URL}/lied/${token}?limit=${limit}&offset=${offset}`;

    setLoading(true)
    try {

      const response = await fetchWithRetry(url, { method: "GET" }, 3, 2000);
      if(!response) {
        console.log('fetchData, response doesnt work / Home');
        return;
      }
      const data = await response.json();
  
      // Verarbeite die ersten 100 Daten
      if (data.data.length === 0) {
        setHasMore(false);
      } else {
        setData((prevData) => {
          // Doppelte vermeiden: Hinzufügen nur, wenn nicht vorhanden
          const newData = data.data.filter(
            (lied: { id: number | undefined; }) => !prevData.some((item) => item.id === lied.id)
          );
          return [...prevData, ...newData];
        });
      }
  
  
    } catch (error) {
      console.error("Fehler beim Abrufen der Lieder", error);
    } finally {
      setLoading(false)
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const tokens = sessionStorage.getItem('accessToken');

  const deleteLiedFunction = async (e: string) => {
    
    const fetchOptions = {
      method: "DELETE",
      headers: {

        Authorization: `${tokens}`
      },
    };
    const fetchOptionsKommentare = {
      method: "DELETE",
      headers: {

        Authorization: `${tokens}`
      },
    };
    const urlDeleteKommentareByLied = `${process.env.REACT_APP_API_URL}/kommentarebylied/${e}`
    const urlDelete = `${process.env.REACT_APP_API_URL}/lied/${e}`;
    setReload(true);

    try {
      await fetch(urlDeleteKommentareByLied, fetchOptionsKommentare).then((response) => response.json()).then((data) => {
        return (console.log("die kommentaren sind geloescht worden"))
      })
    } 
    catch(error) {
      console.log({Message: "die Kommentare koeonnen nicht geloescht werden"})
    }
    try {
      await fetch(urlDelete, fetchOptions)
        .then((response) => response.json())
        .then((data) => {
          
        });
    } catch (error) {
      console.log({ message: "delete Lied error", error });
    }
    setMullModal(() => ({
      ...MullModal,
      mullModalKonditional: false,
      id: ""
    }))
    fetchData(page);
    setReload(false);
    
  };

  const getVerzeichnis = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setErsteKategorie(e.currentTarget.value);
  };

  const suchen = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const deleteNumber = (id: number) => {
    setFavoriten((prevNumbers) => prevNumbers.filter((num) => num !== id));
  };

  const getFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFavoriten((prevFavoriten) => [...prevFavoriten, anders]);

    const anders = parseInt(e.currentTarget.value);

    for (var i = 0; i < favoriten.length; i++)
      if (anders === favoriten[i]) {
        deleteNumber(anders);
      }
  };

    const gefilterteElemente = data?.filter((data) =>
      data?.liedtext?.toLowerCase().includes(sucht?.toLowerCase())
    );

 


  const startFilter = async (e: MouseEvent<HTMLButtonElement>) => {
    setReload(true)
    e.preventDefault();
    setZweiteKategorie(e?.currentTarget?.value);
    if (zweiteKategorie === "" && sucht !== "") {
      setSucht("");
    }
    setReload(false)
  };

  


  const filter =
    ersteKategorie === "Alle"
      ? gefilterteElemente
      : ersteKategorie === "Favoriten"
      ? gefilterteElemente.filter((data) =>
          favoriten.includes(data?.id === undefined ? 0 : data.id)
        )
      : gefilterteElemente.filter(
        
          (data) =>  
           
            data.etappe === zweiteKategorie ||
            data.thematisch?.some(hola2 => hola2 === zweiteKategorie) || 
            data.liturgisch?.some(hola3 => hola3 === zweiteKategorie)

            
          
 );

  const infoToLied = {
    level: level,
    ids: filter,
  };


  const bringSpecificLied = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await navigate(`/lied/${e.currentTarget.value}`, {
        state: { infoToLied },
      });
      await setSpecificLied(e.currentTarget.value.toString());
      await searchParams.delete("param1");
      await setSearchParams(searchParams);
    } catch (error) {
      console.log(`problem bringSpecificLied ${error}`);
    }
  };

  useEffect(() => {
    setGespeicherteSucht(sucht);
    if (location.search) {
      // Eliminar los parámetros de la URL
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("param1");
      const newUrl =
        window.location.origin +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.replaceState({}, "", newUrl);
    }
    fetchData(page);
  }, [sucht]);

  useEffect(() => {
    setGespeicherteLevel(level);
  }, [level]);

  useEffect(() => {
    setGespeicherteFilterLied(zweiteKategorie);

    if (location.search) {
      // Eliminar los parámetros de la URL
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("param2");
      const newUrl =
        window.location.origin +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.replaceState({}, "", newUrl);
    }
    fetchData(page);
  }, [zweiteKategorie]);

  useEffect(() => {
    setGespeicherteFavoriten(favoriten);
  }, [favoriten]);

  useEffect(() => {
    setReload(true)
    
     if(filterEtappe && filterEtappe !== null) {
      setErsteKategorie("Etappen");
    
    setZweiteKategorie(filterEtappe) }
    else if(filterLiturgisch && filterLiturgisch !== null) {
      setErsteKategorie("Liturgisch");
    
      setZweiteKategorie(filterLiturgisch)
    }
    else if(filterThematisch && filterThematisch !== null) {
      setErsteKategorie("Thematisch");
    
      setZweiteKategorie(filterThematisch)
    }
    setReload(false)
  },[filterEtappe !== null])


  useEffect(() => {
    if(entfernungskonditional === true) {

    deleteLiedFunction(MullModal.id);
    }
  })

  useEffect(() => {

    if(!data) {
      setNoData(true)
    }
  }, [data]);

  useEffect(() => {
    if (!hasMore) return;
    const interval = setInterval(() => {
      setPage((prevPage) => prevPage + 1);
    }, 2000); 
  
    return () => clearInterval(interval);
  }, [hasMore]);

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

 
  filter.sort((a, b) => {
    if (a.name === undefined || b.name === undefined) {
      // Handle undefined cases, e.g., sort them to the end
      return a.name ? 1 : -1;
    }
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  const refreshToken = async (): Promise<void> => {
    const refreshToken = sessionStorage.getItem('refreshToken');
  
    if (!refreshToken) {
        throw new Error('No refresh token found');
    }
  
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken }),
    });
  
    if (!response.ok) {
        throw new Error('Token refresh failed');
    }
  
    const { accessToken, idToken } = await response.json();
  
    // Update tokens
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('idToken', idToken);
  };
  
  
  useEffect(() => {
    // const checkAndRefreshTokens = async () => {
    //     const accessToken = sessionStorage.getItem('accessToken');
    //     if (!accessToken) {
    //         console.warn('No access token found. User needs to log in.');
    //         return;
    //     }
  
    //     const getTokenExpirationTime = (token: string): number => {
    //         try {
    //             const payload = JSON.parse(atob(token.split('.')[1]));
    //             return payload.exp; // Ensure this returns a number
    //         } catch (error) {
    //             console.error('Error parsing token expiration time:', error);
    //             return 0; // Default to 0 if parsing fails
    //         }
    //     };
    //     const expirationTime = getTokenExpirationTime(accessToken);
    //     const currentTime = Math.floor(Date.now() / 1000);
  
    //     if (currentTime >= expirationTime) {
    //         console.log('Access token expired. Attempting to refresh...');
    //         await refreshToken();
    //     } else {
    //         console.log('Access token is still valid.');
    //     }
    // };
  
    // Check tokens every 5 minutes (300000 ms)
    const intervalId = setInterval(checkAndRefreshToken, 300000);
  
    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);
 


  return (
    <div className="container"> 
      { noData  ? <p>no sirve</p> : 
    <div>
     
      {level === "" ? (
        <div />
      ) : (
        <div >
          {" "}
          {
            reload === true ? <Spinner/> :
         
          <div className="col">
          <MediaQuery minWidth={1224}>
            <HomeView
              data={filter}
              hola={bringSpecificLied}
              suchen={suchen}
              zweiteKategorie={zweiteKategorie}
              setsucht={setSucht}
              sucht={sucht}
              startFilter={startFilter}
              setOpenModal={setOpenModal}
              level={level}
              getFavorite={getFavorite}
              favoriten={favoriten}
              verzeichnise={verzeichnise}
              getVerzeichnis={getVerzeichnis}
              ersteKategorie={ersteKategorie}
              liturgisch={liturgisch}
              thematisch={thematisch}
              setMullModal={setMullModal}
              loading={loading}
            />{" "}
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <HomeMobileView
              data={filter}
              hola={bringSpecificLied}
              suchen={suchen}
              zweiteKategorie={zweiteKategorie}
              setsucht={setSucht}
              sucht={sucht}
              startFilter={startFilter}
              setOpenModal={setOpenModal}
              level={level}
              getFavorite={getFavorite}
              favoriten={favoriten}
              verzeichnise={verzeichnise}
              getVerzeichnis={getVerzeichnis}
              ersteKategorie={ersteKategorie}
              liturgisch={liturgisch}
              thematisch={thematisch}
              setMullModal={setMullModal}
              loading={loading}
              
            />
          </MediaQuery>
          </div> }
          {/* <Modal show={MullModal} text={"Das Licht ist Erfolgreich entfernt worden"} heading={"Lied entfernt"}/> */}
          <KonditionalModal show={MullModal} setShow={setMullModal} konditional={setEntfernungskonditional}/>
          <Erschaffen openModal={openModal} setOpenModal={setOpenModal}
            // fetchData={fetchData}
             setReload={setReload} />
        </div>

   
          
      )}
    </div> }
    </div>
  );
};

export default Home;
