import {CognitoUser, AuthenticationDetails, CognitoRefreshToken } from "amazon-cognito-identity-js";
import userPool from './amazon-cognito-identity';

interface AuthResult {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  }



export const login = (username: string, password: string) => {
    return new Promise((resolve, reject) => {
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });

        const user = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                console.log("Login erfolgreich!");
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();

                sessionStorage.setItem("accessToken", accessToken);
                sessionStorage.setItem("idToken", idToken);
                sessionStorage.setItem("refreshToken", refreshToken);

                resolve({ accessToken, idToken, refreshToken });
            },
            onFailure: (err) => {
                console.error("Fehler beim Login:", err);
                reject(err);
            }
        });
    });
};


const refreshTokens = () => {
    return new Promise((resolve, reject) => {
        const refreshTokenString = sessionStorage.getItem("refreshToken");

        if (!refreshTokenString) {
            throw new Error("No refresh token found in sessionStorage.");
        }
        
        const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenString });

        const user = new CognitoUser({
            Username: "",
            Pool: userPool, // Stelle sicher, dass dein UserPool-Objekt hier verfügbar ist
        });

        user.refreshSession(refreshToken, (err, session) => {
            if (err) {
                console.error("Error refreshing session:", err);
                return reject(err);
            }

            const newAccessToken = session.getAccessToken().getJwtToken();
            const newIdToken = session.getIdToken().getJwtToken();

            // Tokens aktualisieren
             sessionStorage.setItem("accessToken", newAccessToken);
            sessionStorage.setItem("idToken", newIdToken); 

            resolve({
                accessToken: newAccessToken,
                idToken: newIdToken,
            });
        });
    });
};

export const checkAndRefreshToken = (): Promise<AuthResult> => {
    const accessToken = sessionStorage.getItem('accessToken');
    
    if(!accessToken) {
        return Promise.reject('Kein Access-Token gefunden. Benutzer muss sich anmelden')
    }
    const expirationTime = getTokenExpirationTime(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if(currentTime > expirationTime) {
        console.log('Access-Token abgelaufen, versuche zu erneuern');
        return refreshTokens() as Promise<AuthResult>;
        
    } else {
        console.log('Access-Token ist noch gueltig.');
        return Promise.resolve({
            accessToken,
            idToken: sessionStorage.getItem('idToken') || '',
            refreshToken: sessionStorage.getItem('refreshToken') || '',
        })
    }
}

const getTokenExpirationTime = (token: string): number => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp;
};


 